import Header from './components/header/header'
import HeaderImage from './components/header-image/header-image'
import Footer from './components/footer/footer';
function App() {
  return (
    <div>
      <Header />
      <HeaderImage/>
      <Footer />
    </div>

  )



}

export default App;