
import './header-image.css';

function HeaderImage() {
    return (
        <div className='header-image-wrapper'>
           
        </div>
    )
}

export default HeaderImage;